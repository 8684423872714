import React, { Suspense, lazy } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

/* loader component for Suspense*/
import PageLoader from './components/Common/PageLoader';

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';
// import BaseHorizontal from './components/Layout/BaseHorizontal';

/* Used to render a lazy component with react-router */
const waitFor = Tag => props => <Tag {...props}/>;

const ObteroDashboardPhysician = lazy(() => import('./components/Obtero/DashboardPhysician'));
const ObteroPatientHome = lazy(() => import('./components/Obtero/ObteroPatientHome'));
const ObteroPatientList = lazy(() => import('./components/Obtero/ObteroPatientList'));
const ObteroCodingReview = lazy(() => import('./components/Obtero/ObteroCodingReview'));
const ObteroPopulationHealth = lazy(() => import('./components/Obtero/ObteroPopulationHealth'));
const ObteroSixMonthRecall = lazy(() => import('./components/Obtero/ObteroSixMonthRecall'));
const RiskPanel = lazy(() => import('./components/Obtero/RiskPanel'));
const ObteroMetrics = lazy(() => import('./components/Obtero/Metrics/ObteroMetrics'));
const OldMetrics = lazy(() => import('./components/Obtero/Metrics/OldMetrics'));
const ObteroIntake = lazy(() => import('./components/Obtero/ObteroIntake'));
const ObteroPayment = lazy(() => import('./components/Obtero/ObteroPayment'));
const ObteroQuality = lazy(() => import('./components/Obtero/ObteroQuality'));

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
//    '/login',
//    '/register',
//    '/recover',
//    '/lock',
//    '/notfound',
//    '/error500',
//    '/maintenance'
];

const Routes = ({ location }) => {
    const currentKey = location.pathname.split('/')[1] || '/';
    const timeout = { enter: 500, exit: 500 };

    // Animations supported
    //      'rag-fadeIn'
    //      'rag-fadeInRight'
    //      'rag-fadeInLeft'

    const animationName = 'rag-fadeIn'

    if(listofPages.indexOf(location.pathname) > -1) {
        return (
            // Page Layout component wrapper
            <BasePage>
                <Suspense fallback={<PageLoader/>}>
                    <Switch location={location}>
                        {/*<Route path="/login" component={waitFor(Login)}/>
                        <Route path="/register" component={waitFor(Register)}/>
                        <Route path="/recover" component={waitFor(Recover)}/>
                        <Route path="/lock" component={waitFor(Lock)}/>
                        <Route path="/notfound" component={waitFor(NotFound)}/>
                        <Route path="/error500" component={waitFor(Error500)}/>
                        <Route path="/maintenance" component={waitFor(Maintenance)}/>*/}
                    </Switch>
                </Suspense>
            </BasePage>
        )
    }
    else {
        return (
            // Layout component wrapper
            // Use <BaseHorizontal> to change layout
            <Base>
              <TransitionGroup>
                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
                    <div>
                        <Suspense fallback={<PageLoader/>}>
                            <Switch location={location}>
                                {/*Obtero*/}
                                <Route path="/dashboardphysician" component={waitFor(ObteroDashboardPhysician)} />
                                <Route path="/patients/:id" component={waitFor(RiskPanel)} />
                                <Route path="/patients" component={waitFor(ObteroPatientList)} />
                                <Route path="/codingreview" component={waitFor(ObteroCodingReview)} />
                                <Route path="/obteropopulationhealth" component={waitFor(ObteroPopulationHealth)} />
                                <Route path="/obterosixmonthrecall" component={waitFor(ObteroSixMonthRecall)} />
                                <Route path="/riskpanel/:id" component={waitFor(RiskPanel)} />
                                <Route path="/metrics" component={waitFor(ObteroMetrics)} />
                                <Route path="/intake" component={waitFor(ObteroIntake)} />
                                <Route path="/pcf" component={waitFor(ObteroPayment)} />
                                <Route path="/quality" component={waitFor(ObteroQuality)} />

                                <Redirect to="/patients/1"/>
                            </Switch>
                        </Suspense>
                    </div>
                </CSSTransition>
              </TransitionGroup>
            </Base>
        )
    }
}

export default withRouter(Routes);